*:after,
*:before {
  box-sizing: inherit;
}
html {
  /* margin: 0;
  padding: 0; */
  height: 100%;
}
body {
  background-color: rgb(237, 215, 187);
  width: 100%;
  height: 100%;
  margin: 0;
}

#root {
  margin: 15px 0px;
  display: grid;
  grid-template-columns: 1fr 8fr 1fr;
  grid-template-rows: 20% 70% 10%;
  grid-template-areas:
    ". header ."
    ". contact-us ."
    ". footer .";
  width: 100%;
  height: 100%;
}

#header {
  grid-area: header;

  width: 100%;
  margin-top: 5px;
}

#contact-us {
  grid-area: contact-us;

  width: 100%;
}

#footer {
  grid-area: footer;

  width: 100%;
}
