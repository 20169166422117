#header {
  display: flex;
  align-items: center;
  justify-content: center;
}

#logo {
  width: 210px;
  height: 195px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.5);
  border-radius: 50%;
}
